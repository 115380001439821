import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";

import { GatsbySeo } from "gatsby-plugin-next-seo";

import "twin.macro";

import { Logo } from "@components";
import { Heading, Button } from "@components/shared";

const Page404 = () => (
  <>
    <GatsbySeo title="Nie znaleziono • kulinarnieinaczej.pl" />
    <div tw="absolute inset-0 flex justify-center items-center flex-col bg-primary-pale">
      <Logo />
      <Heading tw="mt-16 mb-8 text-typo text-center">
        Ta strona nie istnieje
      </Heading>
      <Button to="/">
        Strona główna
        <FaLongArrowAltRight />
      </Button>
    </div>
  </>
);

export default Page404;
